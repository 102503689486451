@tailwind base;
@tailwind components;
@tailwind utilities;
/* General styles */
/* General styles */

@keyframes bounce-up-down {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  
  .group-hover\:animate-bounce-up-down:hover img {
    animation: bounce-up-down 1s ease-in-out forwards;
  }
  
  
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    
}

a {
    text-decoration: none;
    color: inherit;
}


/* loader */
#loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: linear-gradient(0deg, rgba(255, 61, 0, 0.2) 33%, #ff3d00 100%);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #263238;
}
@keyframes rotation {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg)}
} 


/* loader */
#loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #333;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  

/* Main navbar styles */


.sticky-nav {
    position: sticky;
    top: 0px; /* Adjust this value as needed */
    z-index: 1000;
    background-color: white;
    box-shadow: 0 2px 5px rgba(191, 189, 189, 0.1);
   
}
.non-sticky {
    position: static;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000000;
    padding: 10px;
    border-bottom: 2px solid #ccc;
}

.navbar-left, .navbar-right, .navbar-center {
    display: flex;
    align-items: center;
}

.navbar-left .icon {
    color: #000000;
    rotate: 270deg;
}
.navbar-right .icon{
    color: #000000;
    rotate: 270deg;
}

.nav-link {
    color: #000000;
    text-decoration: none;
    padding: 8px 10px;
    transition: background 0.3s;
}
.navbar-toggle {
    display: none;
    cursor: pointer;
}
.navbar-toggle-icon {
    font-size: 1.5rem;
    color: #000000;
}



    @media (max-width: 1180px) {
        .navbar {
            flex-wrap: wrap;
           
        }
        .navbar-margins {
             margin-bottom: 50px;
        }
    
        .navbar-left, .navbar-right, .navbar-center {
            flex-direction: column;
            align-items: flex-start;
        }
       
        .navbar-toggle {
            display: block;
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
        }
        .navbar-left.active, .navbar-right.active {
            display: flex;
        }
    
        .navbar-left, .navbar-right {
            display: none;
            width: 100%;
        }
    
        .navbar-center {
            order: -1; /* Moves the center content (logo) to the top */
            margin-bottom: 10px; /* Adds some space below the logo */
        }
    
        .navbar-left.active, .navbar-right.active {
            display: flex;
        }
    }
    
    @media (max-width: 600px) {
        .welcome-container {
            margin-top: 80px;
        }
        .welcome-headings {
        
            font-size: 2.3rem;
        }
     
    }

    .welcome-headings {
        
        font-size: 2.3rem;
    }
    @media (max-width: 600px) {
        .welcome-container {
            margin-top: 50px;
        }
        .welcome-headings {
        
            font-size: 2.3rem;
        }
     
    }

    .welcome-headings {
        
        font-size: 2.3rem;
    }

    .welcome-description {
        font-size: 1rem;
    }

    .custom-input-group {
        flex-direction: column;
        margin: 15px;
        margin: 15px;
    }
    
    
    .custom-form-control {
        margin-bottom: 10px;
    }
    
  
/* darkmode toggle in navbar */

.dark-mode-toggle {
    background-color: #ffe9d2;
    border-radius: 10px;
    margin-right: 10px;
    cursor: pointer;
}
.dark-mode .sticky-nav{
    background-color: black;
}

.dark-mode-toggle .sun, .dark-mode-toggle .moon {
    border-radius: 20px;
    padding: 5px;
}
.dark-mode .navbar-toggle-icon {
color: white;
}

/* signin button in navbar */
 .sign-box{
background-color: rgb(255, 111, 0);
/* padding:10px; */
border-radius: 5px;
color: #f4f4f4;
padding: 10px;
text-decoration: none;
margin-left: 10px;
}
/* dropdown in navbar */
.user-id {
    height: 50px;
    width: 100%;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: space-between;
    padding: 0 10px;
  }
  
  .user-name {
    font-size: 1em;
  }
  
  .dropdown-arrow {
    font-size: 0.8rem;
    transition: all 0.3s ease;
    margin-left: 3px;
  }
  
  .user-id:hover .dropdown-arrow {
    transform: rotate(180deg);
  }
  
  .dropdown-menu {
    display: none;
    position: absolute;
    top: 50px;
    left: 0;
    width: 160px;
    background-color: white;
    z-index: 100; /* Ensure dropdown appears above other elements */
    flex-wrap: wrap;
}

.user-id:hover .dropdown-menu {
    display: block;
}

.dropdown-menu ul {
    list-style-type: none;
    padding: 0; /* Ensure no extra padding around list */
    margin: 0; /* Ensure no extra margin around list */
}

.dropdown-menu ul li {
    display: block;
    height: auto; /* Allow height to adjust based on content */
    width: 100%; /* Use 100% width to ensure it takes the full width of the parent */
    font-size: 1em;
    line-height: 1.5em;
    padding: 5px 10px;
    word-wrap: break-word; /* Enable word wrapping */
    white-space: normal; /* Allow the text to wrap */
    /* border-bottom: 1px solid #333; */
}

.dropdown-menu ul li:last-child {
    border-bottom: none;
}

.dropdown-menu ul li:hover {
    background: #8a8a8a;
    color: #fff;
}

/* Logo Styles */
.navbar-brands {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(255, 111, 0);
    text-decoration: none;
    font-size: 1.3rem;
    margin:0px 0px 0px 10px;
}

.logo-top, .logo-bottom {
    font-weight: bold;
    color: #FF5F00;
    
}

/* Secondary navbar styles */
.secondary-navbar {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    overflow: hidden;
    white-space: nowrap;
    padding: 10px;
    z-index: 10; /* Ensure dropdown appears above other elements */

}

.secondary-navbar-container {
    display: flex;
    width: 100%;
    overflow-x: auto;
    scrollbar-width: none; /* Hide scrollbar on Firefox */
    -ms-overflow-style: none;  /* Hide scrollbar on IE 10+ */
}

.secondary-navbar-container::-webkit-scrollbar {
    display: none;  /* Hide scrollbar on Chrome, Safari, and Opera */
}

.secondary-nav-link {
    padding: 10px 15px;
    white-space: nowrap;
    margin-right: 10px;
}

.secondary-navbar-left :hover {
  color: #FF5F00;
}

.scroll-arrow {
background-color: #ffffff;
width: 20px;
    cursor: pointer;
    padding: 10px 10px;
}

.scroll-arrow .icon {
    font-size: 18px;
    color: black;
}

/* Welcome container styles */
/* Welcome container styles */
.welcome-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    background-color: #fff;
    text-align: center;
    
}

.welcome-content {
    max-width: 600px;
}

.welcome-heading {
    font-size: 2.5rem;
}

.welcome-description {
    font-size: 1.25rem;
    margin: 20px 0;
}

.custom-input-group {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.custom-form-control {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    outline: none;
}

input:focus {
    outline:none !important;
}
div:focus { outline: none; }

.custom-form-control.typing {
    border-color: red;
  }
.custom-btnss {
    background-color: rgb(255, 111, 0);
    color: white;
    border: none;
    padding: 10px 14px;
    cursor: pointer;
    font-size: 1em;
    border-radius: 5px;
    margin-bottom: 10px;

}

.custom-btn:hover {
    background-color: #f79022;
}

/* Media query for larger screens */
@media (min-width: 768px) {
    .custom-input-group {
        flex-direction: row;
        align-items: center;
    }

    .custom-form-control {
        flex: 1;
        margin-bottom: 0;
        margin-right: 10px;
        outline:none;
    }

    .custom-btns {
        margin-left: 0;
        
    }
}


/* Info container styles */
.info-container {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            padding: 20px;
            margin-top: 50px;
            background-color: #f9f9f9;
        }
        .info-box:hover {
            transform: scale(1.05);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
          }
        .info-box {
            background-color: rgb(255, 111, 0);
            color: #f4f4f4;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
            margin: 10px;
            padding: 20px;
            flex: 1 1 calc(25% - 40px);
            box-sizing: border-box;
        }

        .info-box h2 {
            font-size: 1.5rem;
            margin-bottom: 20px;
        }

        .info-box p {
            font-size: 1rem;
            color: #ffffff;
        }

        .icon {
            font-size: 30px;
            color: #ffffff;
        }

        /* Media query for medium screens */
        @media (max-width: 1024px) {
            .info-box {
                flex: 1 1 calc(50% - 40px);
            }
        }

        /* Media query for small screens */
        @media (max-width: 600px) {
            .info-box {
                flex: 1 1 100%;
            }
        }

/* Courses container styles */
.courses-container {
    padding: 20px;
    background-color: #fff;
}

.courses-heading {
    font-size: 30px;
} 
@media (max-width: 1093px) {
    .courses-heading {
        font-size: 23px;
        padding: 8px;
    } 
}

.card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 16px;
    justify-items: center;
}


.cards {
    max-width: 280px;
    height: 330px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.cards:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.cards h4, .cards p, .cards a {
    margin: 15px 10px;
}

.cards h4 {
    font-weight: 600;
}

.cards .fourty {
    font-size: 12px;
    color: #575757;
}

.cards .icon {
    color: #ffb04a;
    font-size: 12px;
    margin-left: 5px;
}

.cards img {
    max-width: 100%;
    height: auto;
}

.cards .tops {
    margin-top: 30px;
    margin-left: 5px;
}

.top .explore-link {
    color: rgb(255, 111, 0);
    text-decoration: none;
    font-weight: bold;
    border: 2px solid chocolate;
    padding: 10px;
    margin-left: 80px;
}

.explore-link:hover {
    text-decoration: underline;
}

.card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.upper .view {
    color: white;
    text-decoration: none;
    font-weight: 600;
    background-color: #FF5F00;
    border-radius: 5px;
    text-wrap: nowrap;
    padding: 10px;
}

/* Media query for screen sizes below 1253px */
@media screen and (max-width: 1279px) {
    .card-container {
        display: grid !important;
        align-items: center; 
        justify-content: center;
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(4, 1fr) !important;
    }
    
}
@media (max-width: 1093px) {
    .card-container {
        display: grid !important;
        align-items: center; 
        justify-content: center;
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(2, 1fr) !important;
    }
    
    .courses-container {
        padding: 20px 50px; 
    }

    .cards {
        flex: 1 1 calc(30% - 20px); /* Adjust width for 3 cards per row */
    }

    .explore-link {
        margin-left: 80px; /* Adjust margin for explore link */
    }
}

/* Media query for screen sizes below 1000px */
@media (max-width: 920px) {
    .courses-container {
        padding: 20px; /* Adjust padding */
    }

    .cards {
        flex: 1 1 calc(50% - 20px); /* Adjust width for 2 cards per row */
    }

    .explore-link {
        margin-left: 80px; /* Adjust margin for explore link */
    }

    .card-container {
        display: grid !important;
        align-items: center; 
        justify-content: center;
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(2, 1fr) !important;
    }
    

}


@media screen and (max-width: 767px) {
    .card-container {
        display: grid !important;
        align-items: center; 
        justify-content: center;
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(2, 1fr) !important;
    }
    
}


/* Media query for screen sizes below 600px */
@media (max-width: 600px) {
    .cards {
        flex: 1 1 100%; /* Adjust width for 1 card per row */
        /* Ensure card takes full width */
        margin: 10px 0; /* Adjust margin */
    }
}

@media (max-width: 480px) {
    .nav-link, .secondary-nav-link {
        padding: 8px 10px;
    }
    .info-box {
        flex: 1 1 100%;
    }
    .explore-link {
        color: rgb(255, 111, 0);
        text-decoration: none;
        font-weight: bold;
        border: 2px solid chocolate;
        padding: 5px;
       
    }

    .big-container .bottom{
        margin-left: 160px;
    }
    .card-container {
        display: grid !important;
        align-items: center; 
        justify-content: center;
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(1, 1fr) !important;
    }
    
}


/* Dark Mode Styles */
.dark-mode body {
    color:white;
     background-color: black;
 }
 
 .dark-mode .navbar {
   
     color: white;
     
 }
 
 .dark-mode {
     background-color: black; /* Dark background color */
     color: #fff; /* Light text color */
 }
 
 .dark-mode .navbar {
     border-color: white; /* Darker border color for navbar */
 }
 .dark-mode .nav-link {
     color: white;
 }
 .dark-mode .secondary-nav-link {
     color: #ffffff; /* Light color for secondary nav links */
 }
 .dark-mode .secondary-navbar-left :hover {
    color: #FF5F00;
  }
 .dark-mode  .secondary-navbar{
    background-color:black;
 }
 
 .dark-mode .scroll-arrow {
     background-color: black; /* Darker background for scroll arrows */
 }
 
 .dark-mode .s {
     background-color: #FF5F00; /* Darker background for buttons */
 }
 
 .dark-mode .info-box {
     background-color: #FF5F00; /* Darker background for info boxes */
 }
 
 .dark-mode .explore-link {
     border-color: #FF5F00; /* Lighter border color for explore link */
     color: #FF5F00; /* Lighter text color for explore link */
 }
 
 .dark-mode .navbar-brand {
     color: #FF5F00; /* Accent color for dark mode navbar brand */
 }
 
 .dark-mode .icon{
     color: white;
 }
 
 .dark-mode .info-container {
     background-color: black;
 }
 .dark-mode .welcome-container {
     background-color: black;
 }
 .dark-mode .courses-container {
     background-color: black;
 }
 .dark-mode .cards {
     background-color: #333;
 }

 .dark-mode  .fourty {
    color: #bbbbbb;
}

/* dropdown */

/* Light Mode Styles */
body.light-mode .user-id {
    background: #fff;
    color: #000;
  }
  
  body.light-mode .dropdown-menu {
    background: rgba(255, 255, 255, 1); /* Set background to white in light mode */
  }
  
  body.light-mode .dropdown-menu ul li {
    color: #000;
    border-bottom: 1px solid #ddd;
  }
  
  body.light-mode .dropdown-menu ul li:hover {
    background: #ddd;
    color: #000;
  }
  
  /* Dark Mode Styles */
  body.dark-mode {
    background-color: black;
    color: white;
  }
  
  body.dark-mode .user-id {
    background: black;
    color: #fff;
  }
  
  body.dark-mode .dropdown-menu {
    background: black;
  }
  
  body.dark-mode .dropdown-menu ul li {
    color: #fff;
    /* border-bottom: 1px solid #363636; */
  }
  
  body.dark-mode .dropdown-menu ul li:hover {
    background: black;
    color: #fff;
  }


   /* scroll button*/
   .scroll-to-top {
    position: fixed;
    bottom: 20px; /* Distance from the bottom of the viewport */
    right: 20px; /* Distance from the right side of the viewport */
    padding: 10px 20px;
    
    border: none;
    border-radius: 5px;
   
    cursor: pointer;
    display: none; /* Initially hidden */
    z-index: 1000; /* Ensures it stays on top */
  }
  .scroll-to-top svg {
    size: 50px;
    fill: #FF5F00; /* Change the color of the SVG icon */
  }

  @media (max-width: 500px) {
    .scroll-to-top{
        right: 1px;        
    }
    .nav-box{
        margin: 10px 10px 30px;
       }
}
@media (max-width: 1180px) {
    .scroll-to-top{

    }
    .nav-box{
        margin: 10px 10px 30px;
       }
}
  /* footer */
  .footerBottom{
    background-color: #000;
    padding: 15px;
    text-align: center;
}
.footerBottom p{
    color: white;
}

/* start job page css */

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    position: relative;
}

.image-container {
    position: relative;
    width: 100%;
    z-index: 1; /* Ensure the image has a lower z-index */
}

.image-container img {
    width: 100%;
    display: block;
}

.job-portal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 40px 20px;
    text-align: center;
    color: white; /* Ensure text is readable against the background */
    z-index: 2; /* Ensure the job-portal is above the image */
    box-sizing: border-box; /* Ensure padding and border are included in width */
}

.job-portal h1 {
    margin: 0;
    font-size: 42px;
    color: rgb(0, 0, 0);
}

.stats {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-top: 30px;
    color: rgb(0, 0, 0);
}

.stats div {
    text-align: center;
}

.stats span {
    font-size: 24px;
    font-weight: bold;
    display: block;
}

.stats p {
    color: grey;
}

.filter-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 1300px;
    margin: 0 auto;
    margin-top: -150px;
    z-index: 3;
    position: relative;
}

form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    align-items: flex-end;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 5px;
}

.form-group select,
.form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.location-group {
    display: flex;
}

.location-group input {
    flex: 1;
}

.add-location {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
}

.form-actions {
    grid-column: span 3;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 20px;
}

.clear,
.apply {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.clear {
    background-color: transparent;
    color: orange;
}

.apply {
    background-color: orange;
    color: #fff;
}

.pagination {
    display: flex;
    justify-content: center;
    padding: 10px 0;
}

.pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    margin: 0 4px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.pagination a.active {
    background-color: orange;
    color: white;
    border: 1px solid orange;
}

.pagination a:hover:not(.active) {
    background-color: #ddd;
}

.job-listings {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;
}

.job-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: left;
    box-sizing: border-box;
}

.job-card img {
    float: left;
    margin-right: 10px;
    width: 60px;
    height: 60px;
}

/* .job-card .tax2win {
    margin-top: 15px;
} */

.job-card h3 {
    margin: 0 0 5px 0;
}

.job-card p {
    margin: 5px 0;
}

.job-card .side {
    display: flex;
    justify-content: space-around;
}

.job-card .side p {
    font-size: 15px;
    color: grey;
}

.job-card .small {
    margin-top: 15px;
    margin-left: 60px;
    font-size: 12px;
    color: grey;
}

.job-card span {
    margin-left: 10px;
}

.job-card .job-icon {
    margin-right: 5px;
}

.job-card .line {
    margin-top: 60px;
}

.job-card button {
    background-color: #ffffff;
    color: orange;
    border: 2px solid orange;
    padding: 10px 18px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 15px;
}

.job-card button a {
    color: orange;
    text-decoration: none;
}

.job-card button:hover {
    background-color: rgb(255, 248, 234);
    color: #ffffff;
}

/* Media Queries */
@media (max-width: 1200px) {
    .filter-form {
        margin-top: -30px;
    }
    .job-listings {
        grid-template-columns: repeat(2, 1fr);
    }
    .job-card {
        width: auto; /* Ensuring the cards take full width of their grid container */
    }
}

@media (max-width: 778px) {
    .job-portal {
        padding: 20px 10px;
    }

    .filter-form {
        margin-top: 200px;
    }

    .stats {
        flex-direction: row;
        align-items: center;
    }

    .filter-form {
        margin-top: -50px;
        padding: 20px 10px;
    }

    .job-listings {
        grid-template-columns: repeat(2, 1fr); /* Two cards per row */
    }

    .job-card {
        width: auto; /* Ensuring the cards take full width of their grid container */
        margin: 10px; /* Ensure there's space between the cards */
    }

    .job-card .line {
        margin-top: 20px; /* Reduced margin-top */
    }

    .job-card .side p {
        font-size: 12px; /* Smaller text size */
    }

    .job-card button {
        padding: 8px 12px; /* Smaller button padding */
        font-size: 14px; /* Smaller button text size */
    }
}

@media (max-width: 576px) {
    .filter-form form {
        display:inline-block;
        width: 100%;
    }

    .form-group {
        min-width: 100%;
    }

    .filter-form {
        margin-top: 100px;
    }

    .job-listings {
        grid-template-columns: 1fr; /* One card per row */
    }

    .job-card {
        width: 100%;
        margin-left:-1px;
    }

    .job-card img {
        width: 40px;
        height: 40px;
    }

    .job-card h3 {
        font-size: 18px;
    }

    .job-card .small {
        margin-left: 50px;
    }

    .job-card .line {
        margin-top: 40px;
    }

    .job-card button {
        padding: 10px 15px;
        font-size: 14px;
    }
}

@media (max-width: 415px) {
    .job-card button {
        padding: 10px 15px;
        font-size: 12px;
    }

 

    .job-card .line {
        margin-top: 35px;
    }

    .job-card .side p {
        font-size: 13px;
    }

    .job-card img {
        margin-top: 12px;
        width: 35px;
        height: 35px;
    }

    .filter-form {
        margin-top: 100px;
    }

    .pagination {
        padding: 7px 0;
    }
    
    .pagination a {
        padding: 6px 11px;
        border-radius: 3px;
    }
}

@media (max-width: 370px) {
    .filter-form form {
        grid-template-columns: 1fr; /* Ensuring one column on very small screens */
    }
    .form-group label {
        margin-top: 10px;
    }
    .job-listings .job-card{
        margin-left: -0.5px;
    }

    .stats span {
        font-size: 19px;
    }

    .stats p {
        font-size: 12px;
    }

    .job-portal h1 {
        font-size: 25px;
    }

    .pagination {
        padding: 4px 0;
    }

    .pagination a {
        padding: 4px 8px;
        border-radius: 3px;
    }
}

/* end job page css */ 

/* job apply page css starts here */ 



.image-container {
    position: relative;
    width: 100%;
    padding-top: 50px;
    z-index: 1;
}

.image-container img {
    width: 100%;
    display: block;
    margin-top: -100px;
}

.container {
    position: absolute;
    display: flex;
    max-width: 100%;
    margin: 20px auto;
    padding: 20px;
    margin-top: -250px;
    z-index: 10;
}

.column {
    flex: 1;
    width: 80%;
    padding: 10px;
    margin-left: 50px;
    color: black;
}

.container .column .jobapply-hr {
    background-color: #414141; /* Make the selector more specific */
    width: 70%;
    margin-left: -5px;
    margin-top: -20px;
    height: 1.2px;
    opacity: 0.5;
    margin-bottom: 20px;
}

.app-option {
    display: flex;
    justify-content: space-between;
}

.headleft {
    width: 75%;
}

.d-headright {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: #ebebeb;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-sizing: border-box; /* Add this line */
}

header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.logo {
    width: 70px;
    height: auto;
    box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.company {
    font-size: 18px;
    font-weight: bold;
    color: grey;
}

header .side {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -12px;
}
header  h1{
    margin-top: 40px;
}
header .side p {
    margin-top: 5px;
}

.company-link {
    text-decoration: none;
    color: #757575;
}

.posted-date {
    color: #757575;
    margin-top: -1px;
    margin-left: 20px;
}

.skills {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: start;
    margin-bottom: 20px;
}

.skill {
    background-color: #e0e0e0;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
}

.ad-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.ad-left {
    width: 75%;
}

.d-ad-right {
    width: 22%;
    display: flex;
    margin-right: -10px;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}



.job-description,
.responsibilities {
    margin-bottom: 20px;
}

.job-description p,
.responsibilities ul {
    margin-bottom: 10px;
}

.responsibilities ul {
    list-style-type: disc;
    padding-left: 20px;
}

.job-details {
    text-align: left;
}

.job-details .apply-icon {
    background-color: #d4d4d4;
    margin-right: 10px;
    padding: 5px;
    border-radius: 10%;
}

.job-details p {
    padding: 5px;
    margin: 0;
}

.job-details .box {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.apply-btn {
    background-color: orange;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.apply-btn:hover {
    background-color: rgb(255, 190, 69);
}

.fa-info-circle {
    color: rgb(145, 145, 251);
    font-size: 15px;
    margin-left: 20px;
}

.apply-date {
    color: #757575;
    margin-top: 15px;
    text-align: center;
    font-size: 0.9em;
    display: block;
}
 

@media (max-width: 1024px) {
    .container {
        padding: 0px;
        margin-top: -100px;
    }

    .app-option {
        flex-direction: column;
    }

    .headleft {
        width: 100%;
        text-align: center;
    }

    .column hr {
        background-color: #d1d1d1;
        width: 100%;
    }

    .ad-section {
        flex-direction: column;
        align-items: center;
    }

    .ad-left {
        width: 100%;
    }

    .d-ad-right {
        width: 40%;
        display: flex;
        justify-content: center;
        height: auto;
        background-color: #ffffff;
    }

    .d-headright {
        width: 100%;
        background-color: #ffffff;
    }

    .job-details {
        text-align: left;
        background: #ffffff;
        box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
        margin-bottom: 20px;
        margin-top: -30px;
        margin-left: -20px;
    }

    .j-btn {
        background: #ffffff;
        width: 100%;
        margin-left: -20px;
    }

    .apply-btn {
        width: calc(100% - 40px); /* Adjust width dynamically */
        max-width: 1024px; /* Set maximum width */
        text-align: center;
    }
    .container .column hr {
        width: 100%;
     
    }
}

@media (max-width: 768px) {
    .container {
        margin-left: -35px;
    }

    .apply-btn {
        width: calc(100% - 20px); /* Adjust width dynamically */
        max-width: none;
        margin: 0 auto;
        text-align: center;
    }

    .skills {
        flex-direction: column;
        align-items: center;
    }

    .skill {
        margin-bottom: 10px;
    }

    .d-ad-right {
        width: 50%;
    }
    .container .column .jobapply-hr {
        width: 100%;
     
    }
}

@media (max-width: 480px) {
    .d-ad-right {
        width: 100%;
    }
    .image-container img {
        margin-top: -50px;
    }

    .container {
        padding: 10px;
    }

    .skills {
        flex-direction: column;
        align-items: center;
    }

    .skill {
        margin-bottom: 10px;
    }

    .job-details {
        padding: 10px;
    }

    .apply-btn {
        width: calc(100% - 10px); /* Adjust width dynamically */
        max-width: none;
        margin: 0 auto;
        text-align: center;
    }
    .container .column .jobapply-hr {
        width: 100%;
     
    }
    .headleft  header h1 {
        font-size: 30px;
    }
}

@media (max-width: 380px) {
  .headleft  header h1 {
        font-size: 25px;
    }

    header .side {
        font-size: 12px;
    }
}
/* job apply page css ends here */

/* detail page css starts here */

    .detail-banner{
        width: 100%;
    }
.deatil-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: auto; /* Allow the deatil-container to scroll */
}

.sidebar {
    flex: 1;
    background-color: #f4f4f4;
    padding: 10px;
    height: 100vh; /* Ensure the sidebar takes the full height */
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
}

.d-above {
    margin-top: 10px;
    height: 35px;
    padding-left: 9px;
    padding-top: 20px;
    background-color: #d0d0d0;
}
.inside{
    font-size: 18px;
    margin-top: -15px;
}

.detail-scrollbar {
    flex: 1;
    overflow-y: auto; /* Enable vertical scrolling */
    padding-left: 9px;
    margin-top: 10px;
}

.d-scroller {
    height: 45px;
    padding-top: 20px;
    font-weight: 400;
}

.d-scroller:hover {
    color: chocolate;
}

.main-content {
    flex: 3;
    display: flex;
    flex-direction: column;
}
.content {
    padding: 20px;
    flex: 1;
    /* Remove overflow: hidden; */
}

.updated-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 10px; */
}

.updated {
    color: #898989;
    font-size: 15px;
}

.d-icons {
    display: flex;
    gap: 20px;
}

.d-icons i {
    font-size: 16px;
    cursor: pointer;
    color: #000000;
}

.d-icons i:hover {
    color: #808080;
}

.detail-hr {
    height: 1.2px; /* Thickness of the line */
    background-color: rgb(92, 92, 92);
    opacity: 0.53;
}

.para {
    word-spacing: 0.6em;
    font-size: 17px;
    color: #474747;
}

.table-content {
    font-size: 18px;
    color: chocolate;
}

.table-content li a {
    color: chocolate;
}

.table-content li {
    margin-top: 10px;
}

.ad {
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    width: 200px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
    padding: 10px; /* Add padding if needed */
    box-sizing: border-box; /* Ensure padding doesn't add to the width */
}

.ad img {
    max-width: 100%;
    height: auto;
    display: block;
}

.d-hamburger-menu {
    display: none;
    font-size: 30px;
    position: fixed;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

/* logo and edit, like and comment section */
.header-content {
    display: flex;
    align-items: center; /* Align items vertically in the center */
    margin-top: 40px;
    border: 0.7px solid #cecece; /* Correctly define the border property */
    padding: 10px; /* Optional: Add padding for better spacing  the border */
}

.header-content img {
    height: 35px;
    width: 35px;
    margin-right: 5px;
}

.d-logo {
    font-size: 20px;
    font-weight: bold;
    display: flex; /* Use flex to align the logo text and image together */
    align-items: center;
    color: chocolate;
}

.interaction {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto; /* Push the interaction section to the right */
    color: #696969;
}

.interaction span {
    margin-left: 10px;
    font-size: 18px;
}
.interaction span .like-count{
    font-size: 15px;

}
/* next and previous section */

.move {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    position: relative;
    color: #5d5d5d;

}

.move .prev, .move .next {
    font-size: 20px; /* Increase the font size */
    margin-bottom: 10px;
}

.prev:hover{
    color: chocolate;
    text-decoration: underline;
}

.next:hover{
    color: chocolate;
    text-decoration: underline;
}

.prev-section, .next-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%; /* Adjust the width as needed */
    font-size: 15px;
}

.prev-section {
    align-items: flex-start;
    text-align: left;
}


.next-section {
    align-items: flex-end;
    text-align: right;
}


.move a {
    text-decoration: none;
    color: #5d5d5d;
    display: flex;
    align-items: center;
}

.move a .fas {
    margin: 0 5px;

}

.separator {
    border-left: 1px solid #ddd;
    height: 50px; /* Adjust the height as needed */
    margin: 0 20px;
}


/* similar read section */

.similar-reads {
    max-width: 1100px;
    margin: 0 auto;
    margin-top: 40px;
}

.similar-reads h2 {
    font-size: 24px;
    color: #8a8a8a;
    margin-bottom: 20px;
}

.article {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.article h3 {
    font-size: 20px;
    color: #000000;
    margin: 0 0 10px;
}

.article p {
    font-size: 16px;
    color: #696969;
    margin: 0 0 20px;
}

.meta {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #999;
}

.meta i {
    margin-right: 5px;
}
/* code for responsiveness */
@media (max-width: 920px){
    .inside{
        font-size: 15px;
    }
}

@media (max-width: 825px){
    .inside{
        font-size: 10px;
    }
    .d-scroller {
        height: 45px;
        font-size: 12px;

    }
    
}
@media (max-width: 768px) {
    .deatil-container {
        flex-direction: column;
        height: auto; /* Reset height for vertical layout */
    }

    .ads {
        order: 3; /* Ensure .ads appears below .content and .sidebar */
    }

    .sidebar, .content {
        height: auto; /* Reset height for vertical layout */
    }

    .d-hamburger-menu {
        display: block;
    }

    .sidebar {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 250px;
        height: 100%;
        z-index: 1000;
        overflow-y: auto;
        transition: transform 0.3s ease;
        transform: translateX(-100%);
    }

    .sidebar.show {
        transform: translateX(0);
        display: block;
    }

    
.move .prev, .move .next {
    font-size: 15px; /* Increase the font size */
    margin-bottom: 8px;
}

.prev-section, .next-section {
 
    font-size: 12px;
}
 
}
@media (max-width: 450px){
    .d-logo{
        font-size: 12px;
    }

    .interaction span {
        font-size: 12px;
    }
    .interaction span .like-count{
        font-size: 12px;
    
    }



}
/* Dropdown styles */
.d-dropdown {
    position: relative;
    display: inline-block;
}

.d-dropdown-menu {
    display: none;
    position: absolute;
    right: 0;
    background-color: white;
    min-width: 150px;
    box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
    z-index: 1;
}

.d-dropdown-menu a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.d-dropdown-menu a i {
    margin-right: 8px;
}

.d-dropdown-menu a:hover {
    background-color: #ddd;
}

.d-dropdown.show .d-dropdown-menu {
    display: block;
}


/* modal 2 */


#openModalBtn {
    padding: 10px 20px;
    font-size: 16px;
}

.modal-overlay {
    display: none; /* Hidden by default */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Increase z-index */
}

.modal-container {
    background-color: white;
    border-radius: 8px;
    width: 80%; /* Adjust width for responsiveness */
    max-width: 600px; /* Max width to prevent overly large modals */
    height: auto; /* Adjust height for responsiveness */
    max-height: 90vh; /* Max height to fit within viewport */
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Allow scrolling if content overflows */
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.modal-header h2 {
    margin: 0;
    color: #000000;
}

.close-button {
    font-size: 30px;
    cursor: pointer;
}

.modal-content {
    margin-top: 20px;
}

.modal-content h3 {
    color: #000000;
}

.modal-content {
    margin-bottom: 20px;
}

.modal-content .blue {
    background-color: aquamarine;
    padding: 20px;
}

.modal-content .blue a {
    color: darkgreen;
}

.suggestions {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Uncomment and use gap for better spacing */
}

.suggestion {
    display: flex;
    align-items: center;
    gap: 20px; /* Adjust gap for better spacing */
}

.suggestion img {
    width: 100px;
    height: 100px;
}

.suggestion h3 {
    margin: 0;
    font-size: 18px;
}

/* Media queries for responsiveness */
@media (max-width: 600px) {
    .modal-container {
        width: 90%; /* Adjust width for smaller screens */
        max-width: none; /* Remove max-width for smaller screens */
        height: auto; /* Adjust height for smaller screens */
        padding: 15px; /* Adjust padding for smaller screens */
    }

    .modal-header h2 {
        font-size: 20px; /* Adjust font size for smaller screens */
    }

    .close-button {
        font-size: 24px; /* Adjust font size for smaller screens */
    }

    .modal-content h3 {
        font-size: 16px; /* Adjust font size for smaller screens */
    }

    .suggestion img {
        width: 80px; /* Adjust image size for smaller screens */
        height: 80px; /* Adjust image size for smaller screens */
    }

    .suggestion h3 {
        font-size: 16px; /* Adjust font size for smaller screens */
    }
}


/* modal 1 */


body {
    font-family: Arial, sans-serif;
}

#showDiscussionBtn {
    padding: 10px 20px;
    font-size: 16px;
}

.discussion-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.discussion-container {
    background-color: white;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;
    height: auto;
    max-height: 90vh;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
}

.discussion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}
.discussion-header span{
    font-size: 14px;
}
.discussion-header h2 {
    margin: 0;
    color: #000000;
}

.discussion-close-btn {
    font-size: 30px;
    cursor: pointer;
}

.discussion-body {
    margin-top: 20px;
}
.discussion-body .left-side{
  margin-left: 85%;
  background-color: coral;
  padding:5px;
  border: coral;
  border-radius: 5px;
}
.discussion-body .head{
    display: flex;
    color: #000000;
}
.discussion-body .fa-user-circle{
    font-size: 30px;
}
.comment-input-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.comment-input-icons {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.comment-input-icons i {
    cursor: pointer;
    font-size: 15px;
    color: #000000;
}

.comment-input {
    flex-grow: 1;
    margin-left: 10px;
}

.comment-input textarea {
    width: 90%;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
}

.comment-input button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.comments .comment {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}
.comments .low{
    display: flex;
    color: #000000;

}

.comment-author {
    font-weight: bold;
    color: #000000;
    margin-left: 10px;
    margin-top: 5px;
}

.comment-author span {
    font-weight: normal;
    color: #888;
    font-size: 12px;
    margin-left: 10px;
}

.comment-text {
    margin: 10px 0;
}

.comment-actions {
    display: flex;
    gap: 10px;
}

.comment-actions button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: #888;
}

.comment-actions button:hover {
    color: #000;
}

/* Modal for improvements */
.modal-overlay {
    display: none; /* Hidden by default */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-overlay.show {
    display: flex; /* Show the modal when the .show class is added */
}

.modal-container {
    background-color: white;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.modal-header h2 {
    margin: 0;
    color: #000000;
}

.close-button {
    font-size: 30px;
    cursor: pointer;
}

.modal-content {
    margin-top: 20px;
}

.modal-content .blue {
    background-color: aquamarine;
    padding: 20px;
}

.modal-content .blue a {
    color: darkgreen;
}

.suggestions {
    display: flex;
    flex-direction: column;
}

.suggestion {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.suggestion img {
    width: 100px;
    height: 100px;
}


/* Media queries for responsiveness */
@media (max-width: 600px) {
    .discussion-container, .modal-container {
        width: 90%;
        max-width: none;
        padding: 15px;
    }

    .discussion-header h2, .modal-header h2 {
        font-size: 20px;
    }

    .discussion-close-btn, .close-button {
        font-size: 24px;
    }

    .comment-input-icons i {
        font-size: 16px;
    }

    .comment-input textarea, .comment-input button {
        font-size: 14px;
    }

    .comments .comment {
        padding: 5px 0;
    }

    .comment-author {
        font-size: 14px;
    }

    .comment-author span {
        font-size: 10px;
    }

    .comment-text {
        font-size: 14px;
    }

    .comment-actions button {
        font-size: 12px;
    }
}

/* detail page css ends here */

/* login page starts here */
.modal-login {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 2000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    backdrop-filter: blur(5px); /* Apply blur effect */
  }
  
  .modal-login.show {
    display: block; /* Show the modal when needed */
  }
  
  .login-container {
    display: flex;
    width: 70%;
    height: 80vh;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: 70px auto;
    position: relative; /* Added for positioning the close button */
    box-sizing: border-box; /* Added for equal spacing */
    border-radius: 10px;
  }
  
  .close {
    color: #ffffff;
    font-size: 42px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  

.login-container {
    display: flex;
    width: 70%;
    height: 85vh;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin-top: 70px;
    margin-left: 240px;
    margin-right: auto;
    position: relative; /* Added for positioning the close button */
    box-sizing: border-box; /* Added for equal spacing */
}


.lefts, .rights {
    padding: 40px;
    flex: 1;
}

.lefts {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.lefts .boxs {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.lefts h2 {
    margin-bottom: -1px;
    font-size: 24px;
}

.lefts p {
    margin-bottom: 15px;
    color: #777;
}

.boxs form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

label {
    font-size: 14px;
    color: #333;
}

input[type="email"], input[type="password"],input[type="text"] {
    padding: 8px;
    margin-top: -13px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
}

.remember-forgots {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 5px; */
}

.remember-forgots a {
    margin-left: 140px;
}

.login-form button {
    padding: 10px;
    background-color: #ff6600;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin-top: -10px;
}

button:hover {
    background-color: #e65c00;
    color: white;
}

.ors {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #777;
    margin-top: 20px;
}

.ors hr {
    flex: 1;
    border: none;
    border-top: 1px solid #ccc;
    margin: 0 10px;
}

.ors p {
    margin: 0;
}

.social-logins {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.social-logins a {
    color: #ff6600;
    font-size: 48px; /* Decreased from 48px */
    margin: 0 10px;
}

.social-logins .cross {
    background-color:#ff6600;
    color: #f9f9f9;
    font-weight: 100;
}

.registers {
    margin-top: 10px;
    text-align: center;
    color: #777;
    font-size: 15px;
}

.registers a {
    color: #ff6600;
}

.rights {
    background-color: #ffa07a;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px 0 0 30px; /* Changed to match left radius on column view */
}

.rights img {
    width: 80%;
}

@media (max-width: 1290px) {
    .remember-forgots {
        font-size: 15px;
    }
    .remember-forgots a {
        margin-left: 110px;
    }
}

@media (max-width: 1280px) {
    .remember-forgots a {
        margin-left: 100px;
    }
}

@media (max-width: 1196px) {
    .remember-forgots a {
        margin-left: 90px;
    }
}

@media (max-width: 1110px) {
    .remember-forgots a {
        margin-left: 80px;
    }
}

@media (max-width: 1055px) {
    .login-container {
        margin: 0 10%;
        width: auto;
        margin-top: 70px;
    }
}

@media (max-width: 768px) {
    .login-container {
        flex-direction: column;
        width: 90%;
        margin: 0;
        height: auto;
    }

    .lefts, .rights {
        padding: 20px;
        flex: none;
        width: 100%;
    }

    .rights {
        display: none;
    }

    .remember-forgots a {
        margin-left: 140px;
    }

    .social-logins a {
        font-size: 36px;
    }

    .login-container {
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: auto;
    }

    .close {
        position: absolute; /* Change from relative to absolute */
        top: 10px; /* Adjust top position as needed */
        right: 10px; /* Align to the right */
        cursor: pointer;
        margin-left: auto; /* Remove margin-left */
        color: orange;
    }
}

@media (max-width: 432px) {
    .remember-forgots {
        font-size: 12px;
    }
    .remember-forgots a {
        margin-left: 100px;
    }
}

@media (max-width: 385px) {
    .remember-forgots {
        font-size: 12px;
    }
    .remember-forgots a {
        margin-left: 80px;
    }
}


/* login page ends here */

/* register page starts here */

.register-icons a {
    font-size: 20px; /* Decreased from 48px */
    margin: 0 10px;
}

/* register page ends here */



/* catalogue */
/* src/components/CourseCategory.css */
.catelogue-container {
    margin:0px 300px;
}

@media (max-width: 1080px) {
    .catelogue-container {
        margin:0px 150px ;
    }
}

@media (max-width: 686px) {
    .catelogue-container {
        margin:0px  ;
    }
}
/* @media (min-width: 768px) {
    .catelogue-container {
        margin:0px 300px;
    }
} */

.course-category-catalogue {
    text-align: center;
    padding: 2rem;
    background: linear-gradient(to right, #f5b481, #ffe3c4);
    color: #333;
  }
  
  .course-category-catalogue h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .search-bar-catalogue {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
  
  .search-bar-catalogue input {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 300px;
    margin-right: 0.5rem;
  }
  
  .search-bar-catalogue button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    background-color: #ff6600;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .course-categories-catalogue {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .category-catalogue {
    padding: 1rem;
    width: 150px;
    border-radius: 8px;
    color: white;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s;
  }
  
  .category-catalogue:hover {
    transform: scale(1.05);
  }
  
  .live-course-catalogue {
    background-color: #ff5722;
  }
  
  .fundamental-course-catalogue {
    background-color: #5d4037;
  }
  
  .dsa-placement-catalogue {
    background-color: #ffccbc;
    color: #333;
  }
  
  .web-development-catalogue {
    background-color: #6d4c41;
  }
  
  .ml-data-science-catalogue {
    background-color: #ff9800;
  }
  
  .cloud-devops-catalogue {
    background-color: #795548;
  }
  
/* newspage css starts from here */
.news-container {
    margin: 0;
    padding: 20px;
    height: auto;
    text-align: center;
    background-color: rgb(255, 113, 19);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bg-orange-200 {
    width: fit-content;
    border-radius: 10px;
}

.max-w-4xl {
    max-width: 50rem; /* 896px */
}

.max-w-2xl {
    max-width: 79rem; /* 672px */
}

.max-w-xs {
    max-width: 20rem; /* 320px */
}

.news-search-input {
    background-color: transparent;
    border: 4px solid white;
    transition: border-color 0.3s ease;
    width: 100%;
}

.news-search-input:focus {
    border-color: rgba(255, 255, 255, 0.7);
}

.search-button {
    border-radius: 10px; /* Fully rounded corners */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.search-button:hover {
    background-color: #ff8740;
    box-shadow: 0 0 10px rgba(255, 135, 64, 0.5);
}

@media (min-width: 768px) {
    .text-4xl {
        font-size: 2.25rem; /* 36px */
    }
}



.news-cat-head {
    text-align: center;
    font-size: 38px;
}




.news-content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}



.card1 {
    background-image: url('../assets/code1.png');
}

.card2 {
    background-image: url('../assets/code2.jpeg');
}

.card3 {
    background-image: url('../assets/code1.png');
}

.card4 {
    background-image: url('../assets/code3.jpeg');
}

.card5 {
    background-image: url('../assets/code2.jpeg');
}

.card6 {
    background-image: url('../assets/code3.jpeg');
}

.card7 {
    background-image: url('../assets/code2.jpeg');
}

.card8 {
    background-image: url('../assets/code1.png');
}

.card9 {
    background-image: url('../assets/code3.jpeg');
}

.news-category {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin: 0 auto; /* Center the container */
    margin-top: 30px;
}

.news-cards {
    display: flex;
    overflow-x: hidden; /* Disable horizontal scrolling */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    width: 80%; /* Adjust to the content's width */
    margin-top: 30px;
    margin-bottom: 40px;
    position: relative; /* Ensure relative positioning for absolute arrows */
}

.news-cards::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.news-card {
    min-width: 190px;
    height: 90px;
    margin: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    flex-shrink: 0;
    color: white;
    font-size: 18px;
    font-weight: bold;
    background-size: cover; /* Ensure the image covers the entire card */
    background-position: center; /* Center the image */
    white-space: normal; /* Allow text to wrap */
    text-align: center; /* Center the text */
}

.news-arrow {
    background-color: rgb(255, 106, 0);
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 10px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.news-arrow.left {
    left: 65px; 
}

.news-arrow.right {
    right: 65px;
}

.news-indicators {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.news-indicator {
    width: 10px;
    height: 10px;
    background-color: lightgray;
    border-radius: 50%;
    margin: 0 5px;
}

.news-indicator.active {
    background-color: chocolate;
}

/* Media Queries */
@media (max-width: 768px) {
    .news-item {
        width: 100%; /* Full width on smaller screens */
    }
    .news-card {
        min-width: 110px;
        height: 50px;
        font-size: 12px;
        font-weight: 900;
    }
    .news-cards {
        width: 71%; /* Full width on smaller screens */
        overflow-x: hidden; /* Disable horizontal scrolling */
        scroll-behavior: smooth; /* Smooth scrolling */
        -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
        position: relative; /* Ensure relative positioning for absolute arrows */
    }
    .news-indicators {
        margin-top: -10px;
    }
    .news-arrow {
        display: block; /* Show arrows on smaller screens */
        position: absolute; /* Position arrows relative to the news-cards container */
        font-size: 16px; /* Reduce arrow size */
        padding: 6px; /* Adjusted padding */
        width: 30px; /* Adjusted width */
        height: 30px; /* Adjusted height */
        line-height: 20px;
        margin-top: -10px;
    }
    .news-arrow.left {
        left: 8px; /* Adjusted left position */
    }
    .news-arrow.right {
        right: 8px; /* Adjusted right position */
    }
}
 

 /* 404 error page  */

/* src/screens/NotFoundPage.css */
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
}

.main-img {
  padding-top: 8%;
}

.main-description {
  font-size: 14px;
}

.btn-sz-primary {
  font-size: 14px;
  padding: 10px 25px;
  border-radius: 50px;
  color: white !important;
  background-color: rgb(224, 80, 80) !important;
}
